import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useFoldersList(root, props) {
  // Use toast
  const toast = useToast()
  const refFoldersListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name', label: 'Folder', sortable: true,
    },
    // {
    //   key: 'created_by', label: 'Created By', sortable: true, class: 'fixed-width_created_by',
    // },
    {
      key: 'items', label: 'Items Inside', class: 'fixed-width_items_inside', sortable: true,
    },
    {
      key: 'actions', label: 'Actions', class: 'fixed-width_actions',
    },
  ]

  const tableItems = ref([])
  const defaultPerPage = Number(localStorage.getItem('foldersPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const isLoading = ref(false)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    // eslint-disable-next-line no-use-before-define
    fetchFoldersList()
  }

  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-form-folders', 'foldersCurrentPage', currentPageVal)
        refetchData()
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-form-folders', 'foldersPerPage', perPageVal)
        refetchData()
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-form-folders', 'foldersSearchQuery', searchQueryVal)
        }, 250)
        refetchData()
      }
    })

  const fetchFoldersList = () => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: props.folderType,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value

    isLoading.value = true

    store
      .dispatch('app-folders/fetchFoldersList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        tableItems.value = data
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
        const folderId = router.currentRoute.query.folder_id
        if (folderId) {
          // eslint-disable-next-line eqeqeq
          const folder = data.find(item => item.id == folderId)
          store.commit('app-folders/SET_SELECTED_FOLDER', folder)
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching folders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const createFolder = queryParams => axios
    .post('auth/folder', queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Folder was created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Folder',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateFolder = queryParams => axios
    .post('auth/folder', queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Folder was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Folder',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const setFolder = queryParams => axios
    .post('/auth/folder/set', queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Folder was successfully changed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error changing Folder',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteFolder = ID => axios
    .delete('/auth/folder/delete', {
      data: { id: ID },
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Folder',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Folder',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchFolder = ID => axios
    .get(`auth/folder/${ID}/edit`, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Folder',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  return {
    fetchFoldersList,
    createFolder,
    updateFolder,
    deleteFolder,
    setFolder,
    fetchFolder,
    tableColumns,
    tableItems,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    isLoading,
    refFoldersListTable,
    refetchData,
  }
}
