import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useFormList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Form', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'created_by', label: 'Created by', sortable: true },
    { key: 'actions', label: 'Actions' },
  ]

  const tableItems = ref([])
  const currentRouteName = router.currentRoute.name
  const defaultPerPage = Number(localStorage.getItem('formsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const isLoading = ref(false)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const selectedFolder = computed(() => store.getters['app-folders/getSelectedFolder'])

  const refetchData = () => {
    // eslint-disable-next-line no-use-before-define
    fetchFormList()
  }

  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'formsCurrentPage', currentPageVal)
        refetchData()
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'formsPerPage', perPageVal)
        refetchData()
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, currentRouteName, 'formsSearchQuery', searchQueryVal)
          refetchData()
        }, 250)
      }
    })

  const fetchFormList = () => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      form_folder: true,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value
    if (router.currentRoute.query.folder_id) payloadData.folder_id = router.currentRoute.query.folder_id

    isLoading.value = true

    store
      .dispatch('app-form/fetchFormsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        tableItems.value = data
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching forms' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteForm = ID => axios
    .delete(`auth/forms/${ID}`, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting form',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const cloneForm = ID => axios
    .get(`auth/forms/${ID}/clone`, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success cloning form',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error cloning form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-form-edit' },
  ]

  return {
    fetchFormList,
    tableColumns,
    tableItems,
    selectedFolder,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    isLoading,
    refInvoiceListTable,

    actionOptions,

    resolveStatusVariant,

    deleteForm,
    cloneForm,

    refetchData,
  }
}
