import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormsSetting() {
  // Use toast
  const toast = useToast()

  const postForm = queryParams => axios
    .post('/auth/forms', queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New form has posted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting form',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updForm = queryParams => axios
    .put(`/auth/forms/${queryParams.id}`, queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'From was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating form',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    postForm,
    updForm,
    fetchForm,
  }
}
