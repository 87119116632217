<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4
              v-if="selectedFolder"
            >
              <feather-icon
                class="text-primary cursor-pointer"
                icon="ArrowLeftIcon"
                size="19"
                @click="backToPrevPage"
              />
              {{ selectedFolder.name }}
            </h4>
            <h4 v-else>
              Root Forms
            </h4>
          </b-col>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-between mb-1 mb-md-0"
          >
            <div>
              <label class="mr-1 mb-0">Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :value="perPage"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @input="updatePerPage"
              />
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="searchInput"
              />

              <b-button
                v-b-modal.new-form-modal
                class="ml-1"
                variant="primary"
              >
                <span class="mr-25 align-middle">Create Form</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <div class="position-relative table-responsive table-sticky-head b-table-sticky-header" style="max-height: 70vh; min-height: 20vh">
          <table class="table b-table table-hover">
            <thead>
              <tr>
                <th
                  v-for="(col, index) in tableColumns"
                  :key="index"
                >{{ col.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in tableItems"
                :id="item.id"
                :key="item.id"
                @dragstart="dragStart($event, item.id)"
              >
                <td class="handle">
                  <b-link
                    :to="{ name: 'admin-form-edit', params: { id: item.id } }"
                  >{{ item.name }}</b-link>
                </td>
                <td>
                  <b-badge
                    :variant="`light-${resolveStatusVariant(item.is_active)}`"
                  >
                    {{ item.is_active ? 'Active' : 'Inactive' }}
                  </b-badge>
                </td>
                <td>{{ item.user }}</td>
                <td>
                  <!-- Dropdown -->
                  <b-dropdown
                    variant="link"
                    toggle-class="mx-auto p-0"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      no-caret
                      :to="{ name: 'admin-form-edit', params: { id: item.id } }"
                    >
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      no-caret
                      @click="delForm(item)"
                    >
                      <span>Delete</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      no-caret
                      @click="moveTo(item)"
                    >
                      <span>Move to</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="cloneFormAndUpdateTable(item.id)"
                    >
                      <span>Clone Form</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <confirmation-modal
        :type="'-form-delete'"
        :title="'The form is used for the registration form'"
        :message="'Are you sure you want to delete?'"
        :close-only="true"
        @close-modal="$bvModal.hide('confirmation-form-delete')"
        @confirm="confirmationDelete"
      />

      <move-to-folder-modal
        :item="selectedForm"
        :folder-type="'form'"
        @refetchData="refetchData"
      />

      <new-form-modal
        :folder-id="folderId"
        @refetchData="refetchData"
      />
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BPagination, BOverlay,
  BDropdown, BDropdownItem, BBadge, BButton, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import formStoreModule from '@/views/admin/forms/formStoreModule'
import useFormList from '@/views/admin/forms/form-list/useFormList'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import MoveToFolderModal from '@/views/components/folders-list/modals/MoveToFolderModal.vue'
import NewFormModal from '@/views/admin/forms/form-list/modals/NewFormModal.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    BLink,

    // draggable,
    vSelect,

    ConfirmationModal,
    MoveToFolderModal,
    NewFormModal,
  },
  data() {
    return {
      selectedForm: {},
    }
  },
  computed: {
    forceReload() {
      return this.$store.getters['app-folders/getForceReload']
    },
    folderId() {
      return this.selectedFolder ? this.selectedFolder.id : null
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-form'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, formStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchFormList,
      tableColumns,
      tableItems,
      selectedFolder,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      isLoading,
      refInvoiceListTable,

      actionOptions,

      refetchData,

      deleteForm,
      cloneForm,

      resolveStatusVariant,
    } = useFormList(root, props)

    return {
      fetchFormList,
      tableColumns,
      tableItems,
      selectedFolder,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      dataMeta,
      isSortDirDesc,
      isLoading,
      refInvoiceListTable,

      actionOptions,

      refetchData,

      deleteForm,
      cloneForm,

      resolveStatusVariant,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.formsCurrentPage) {
        this.setFilterParams()
      }
    },
    forceReload() {
      this.refetchData()
    },
    selectedFolder() {
      this.refetchData()
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
    await this.fetchFormList()
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('formsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('formsPerPage')
      this.currentPage = Number(query.formsCurrentPage) || 1
      this.perPage = setPerPage(query.formsPerPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.formsSearchQuery || ''
    },
    async cloneFormAndUpdateTable(id) {
      await this.cloneForm(id)
      this.refetchData()
    },
    delForm(form) {
      this.selectedForm = form
      if (form.is_use_school_registration_forms) {
        this.$bvModal.show('confirmation-form-delete')
      } else {
        this.deleteForm(form.id)
      }
    },
    async confirmationDelete() {
      await this.deleteForm(this.selectedForm.id)
      this.selectedForm = {}
      this.$bvModal.hide('confirmation-form-delete')
    },
    moveTo(form) {
      this.selectedForm = form
      this.$nextTick(() => {
        this.$bvModal.show('move-to-folder-modal')
      })
    },
    dragStart(event, formId) {
      const data = {
        id: formId,
        type: 'form',
      }
      event.dataTransfer.setData('text/plain', JSON.stringify(data))
    },
    backToPrevPage() {
      store.commit('app-folders/SET_SELECTED_FOLDER', null)

      const route = {
        name: this.$router.currentRoute.name,
      }
      this.$router.push(route)
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.searchInput {
  width: 120px;
}
</style>
